import {
  ActionButton,
  IButtonStyles,
  IIconStyles,
  Stack,
  Text,
} from "@fluentui/react";
import styles from "./App.module.css";

function App() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1 className={styles.heading}>
          <Text variant="xxLargePlus">Yuye Zhang</Text>
        </h1>
        <Text variant="large"> | Software Engineer in Seattle, WA</Text>
      </div>
      <Links />
      <Stack className={styles.jobs} tokens={{ childrenGap: 30 }}>
        <Job
          employer="Microsoft Azure"
          location="Redmond, WA"
          role="Principal Software Engineer"
          dates="2014 – present"
        />
        <Job
          employer="University of Washington"
          location="Seattle, WA"
          role="M.S. Computer Science & Engineering"
          dates="2022 – present"
        />
        <Job
          employer="Carnegie Mellon University"
          location="Pittsburgh, PA"
          role="B.S. Computer Science"
          dates="2010 – 2013"
        />
        <Job
          employer="Experimental High School Attached to Beijing Normal University"
          location="Beijing, China"
          role="Accelerated Competition Program"
          dates="2004 – 2010"
        />
      </Stack>
    </div>
  );
}

function Links() {
  return (
    <Stack horizontal>
      <ActionButton
        iconProps={{ iconName: "LinkedInLogo", styles: iconStyles }}
        href="https://www.linkedin.com/in/yuyezhang/"
        target="_blank"
        rel="noopener"
        styles={buttonStyles}
      >
        LinkedIn
      </ActionButton>
      <ActionButton
        iconProps={{ iconName: "PictureCenter", styles: iconStyles }}
        href="https://www.instagram.com/daleprc/"
        target="_blank"
        rel="noopener"
        styles={buttonStyles}
      >
        Instagram
      </ActionButton>
      <ActionButton
        iconProps={{ iconName: "Mail", styles: iconStyles }}
        onClick={() => {
          window.open(
            atob("bWFpbHRvOmRhbGUucHJjQGxpdmUuY29t"),
            "_blank",
            "noopener"
          );
        }}
        styles={buttonStyles}
      >
        Email
      </ActionButton>
    </Stack>
  );
}

function Job(props: {
  employer: string;
  location: string;
  role?: string;
  dates: string;
}) {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack.Item>
        <Text variant="xLarge">{props.employer}</Text>
        <Text variant="medium"> | {props.location}</Text>
      </Stack.Item>
      {props.role && <Text variant="mediumPlus">{props.role}</Text>}
      <Text variant="mediumPlus">{props.dates}</Text>
    </Stack>
  );
}

const buttonStyles: IButtonStyles = {
  root: { padding: "0 8px 0 0", border: 0 },
};
const iconStyles: IIconStyles = {
  root: { color: "#000", margin: "0 4px 0 0" },
};

export default App;
